import axios from "axios";

let API_URL = "https://avisquiz.co.za/api";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:8000";
}

// console.log('API_URL', API_URL)

const createParticipant = ({
  name,
  surname,
  cell,
  email,
  receiveInfo
}) => {
  return axios.post(`${API_URL}/participant`, {
    name,
    surname,
    cell,
    email,
    receive_info: receiveInfo
  });
};

const getQuestions = id => {
  return axios.get(`${API_URL}/questions/${id}`);
};

const postAnswers = ({ id, answers }) => {
  return axios.put(`${API_URL}/participant`, {
    id,
    answers
  });
};

const getPrize = id => {
  return axios.get(`${API_URL}/prize/${id}`);
};

const getLeaderboard = id => {
  return axios.get(`${API_URL}/leaderboard/${id}`);
};

export {
  createParticipant,
  getQuestions,
  postAnswers,
  getPrize,
  getLeaderboard
};
