<template>
  <div class="row h-100 w-100 mx-0 justify-content-center justify-content-lg-start align-items-center overflow-scroll py-5">
    <div class="col-10 offset-lg-1 py-5 mh-100">
      <router-view/>
    </div>
  </div>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  height: 100%;
}
</style>
