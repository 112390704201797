import { createRouter, createWebHistory } from 'vue-router'
import Form from '../views/Form.vue'

const routes = [
  {
    path: '/',
    name: 'Form',
    component: Form
  },
  // {
  //   path: '/form',
  //   name: 'Form',
  //   component: () => import('../views/Form.vue')
  // },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/quiz/:id',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue')
  },
  // {
  //   path: '/result',
  //   name: 'Result',
  //   component: () => import('../views/QuizResult.vue')
  // },
  {
    path: '/result/:id/:correct/:time',
    name: 'Result',
    component: () => import('../views/QuizResult.vue')
  },
  {
    path: '/leaderboard/:id',
    name: 'Leaderboard',
    component: () => import('../views/Leaderboard.vue')
  },
  {
    path: '/prizes/:id',
    name: 'Prizes',
    component: () => import('../views/Prizes.vue')
  },
  {
    path: '/congrats',
    name: 'Congrats',
    component: () => import('../views/Congrats.vue')
  },
  {
    path: '/sorry',
    name: 'Sorry',
    component: () => import('../views/Sorry.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
