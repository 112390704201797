<template>
  <div class="background-image"></div>
  <img src="../assets/images/logo_avis.png" class="logo" >
  <h1 class="pb-4">
    <p>
      <strong>ENTER YOUR DETAILS</strong>
    </p>
    <p>
      SO WE CAN GET STARTED
    </p>
  </h1>
  <form class="row mx-0 position-relative" @submit.prevent="submitForm">
    <div class="col-12 p-0">
      <div class="row mx-0 mb-4">
        <div class="col-6 p-0">
          <div class="form_label pb-2">
            Name:
          </div>
          <div class="col-10">
            <input type="text" class="form-control py-3 fs-4" v-model.trim="name" required />
          </div>
        </div>
        <div class="col-6 p-0">
          <div class="form_label pb-2">
            Surname:
          </div>
          <div class="col-10">
            <input type="text" class="form-control py-3 fs-4" v-model.trim="surname" required />
          </div>
        </div>
      </div>
      <div class="row mx-0 mb-5">
        <div class="col-6 p-0">
          <div class="form_label pb-2">
            Cell:
          </div>
          <div class="col-10">
            <input type="text" class="form-control py-3 fs-4" pattern="[0-9]{9,10}" title="must be of the format 0821234567 or 076234567" v-model="cell" required />
          </div>
        </div>
        <div class="col-6 p-0">
          <div class="form_label pb-2">
            Email:
          </div>
          <div class="col-10">
            <input type="email" class="form-control py-3 fs-4" v-model="email" required />
          </div>
        </div>
      </div>
      <div class="row mx-0 mb-4">
        <div class="col-12 ps-0">
          <label class="container_checkbox">
            <div class="form_label">Receive Product Information from Avis Southern Africa</div>
          </label>
        </div>
      </div>
      <div class="row mx-0">
        <div class="radio-toolbar">
          <div class="radio-option">
            <label class="form_label" for="radioYes">Yes</label>
            <input type="radio" id="radioYes" value="true" v-model="receiveInfo" class="square-radio-button"/>
          </div>

          <div class="radio-option">
            <label class="form_label" for="radioNo">No</label>
            <input type="radio" id="radioNo" value="false" v-model="receiveInfo" class="square-radio-button"/>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 mb-0">
      <div class="col-12 p-2 d-flex justify-content-end">
        <button type="submit" class="arrow-button">
          <span class="arrow-icon">&gt;</span>
        </button>
      </div>
    </div>
    <div class="col-auto absolute_bottom start-50 translate-middle">
    </div>
  </form>
</template>

<script>
import { computed, ref } from 'vue'
import { createParticipant } from '../api'
import { useStore } from 'vuex'

export default {
  name: 'Form',
  setup () {
    const store = useStore()

    const name = ref('')
    const surname = ref('')
    const cell = ref('')
    const email = ref('')
    const receiveInfo = ref(null)

    if (process.env.NODE_ENV !== 'production') {
      name.value = 'test'
      surname.value = 'test'
      cell.value = '0987654320'
      email.value = 'qwe@qwe.com'
      receiveInfo.value = 'false'
    }

    return {
      name,
      surname,
      cell,
      email,
      receiveInfo,
      questions: computed(() => store.state.questions)
    }
  },
  methods: {
    async submitForm () {
      // console.log('SUBMIT')
      const loader = this.$loading.show()
      try {
        const response = await createParticipant({
          name: this.name,
          surname: this.surname,
          cell: this.cell,
          email: this.email,
          receiveInfo: (this.receiveInfo === 'true')
        })
        loader.hide()
        // console.log('response', response.data)
        if (response.data.status) {
          this.$store.dispatch('setUserQuestions', response.data.data.questions)
          this.$router.push(`/quiz/${response.data.data.participant.id}`)
        } else {
          throw new Error(response.data.message)
        }
      } catch (error) {
        loader.hide()
        console.log('response', error)
        if (error.response) {
          const errors = error.response.data
          let errorMessage = ''
          for (const key in errors) {
            errorMessage += errors[key] + '\r\n'
          }
          alert(errorMessage)
        }
      }
    }
  }
}
</script>

<style scoped>
.form-control {
  border-radius: 0;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.8rem;
}
.form_label {
  font-size: 1.3rem;
  line-height: 1;
}
.absolute_bottom {
  position: absolute;
  bottom: -7rem;
  text-align: center;
}

.container_checkbox {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* width: fit-content; */
}
.container_checkbox .form_label {
  padding-right: 40px;
  /* line-height: 25px; */
}
/* Hide the browser's default radio button */
.container_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 0;
  height: 19px;
  width: 19px;
  background-color:rgb(252, 181, 51);
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container_checkbox input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container_checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* Show the indicator (dot/circle) when checked */

/* BUTTON */
.orange_bg {
  background-color:rgb(252, 181, 51);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: inherit;
}
.blue_bg {
  background-color:rgb(0, 255, 255);
  transform: rotate(1deg);
  height: 107%;
  width: calc(100% + 3px);
  left: 1px;
  top: -1px;
  position: absolute;
  z-index: inherit;
}
.holder {
  color: #000;
  position: relative;
  height: 33px;
  width: fit-content;
  max-width: 85vw;
  cursor: pointer;
}
.text {
  position: relative;
  z-index: 3;
  text-transform: uppercase;
  font-size: 25px;
  width: max-content;
}
.radio-toolbar {
  display: flex;
  /* justify-content: center; */
  gap: 20px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.radio-option input[type="radio"] {
  -webkit-appearance: none; /* Remove default styling for WebKit browsers */
  -moz-appearance: none; /* Remove default styling for Mozilla browsers */
  appearance: none; /* Remove default styling */
  width: 50px; /* Width of square */
  height: 50px; /* Height of square */
  border: 2px solid #444; /* Border of square */
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  background-color: white; /* Background color of square */
}

.radio-option input[type="radio"]:checked:after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px; /* Size of checkmark */
  color: #444; /* Color of checkmark */
}

/* Optional: Add a hover effect for radio options */
.radio-option input[type="radio"]:hover {
  background-color: #efefef;
}

/* Label styling */
.radio-option label {
  margin-bottom: 0;
}
.arrow-button {
  border: none;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  width: 100px;
}

.arrow-button:focus {
  outline: none;
}

.arrow-icon {
  display: inline-block;
  color: #d3202f;
  font-size: 24px; /* Change size as needed */
}
.background-image {
  position: fixed; /* or 'absolute' if you want it to scroll with the page */
  top: 0;
  left: 0;
  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  background-size: cover; /* cover the entire viewport */
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* send it to the back */
  background-image: url("../assets/images/bg_avis.png") !important;
}

@media (min-width: 992px) {
  .form-control {
    font-size: 1rem;
  }
  .form_label {
    font-size: 28px;
    line-height: 1;
  }
  .checkmark {
    height: 24px;
    width: 24px;
  }
  .container_checkbox .checkmark:after {
    left: 8px;
    top: 1px;
    width: 8px;
    height: 17px;
  }
  /* BUTTON */
  .holder {
    height: 40px;
  }
  .text {
    font-size: 30px;
  }
}
</style>
<!-- <style>
  @media (min-width: 992px) {
    body {
      background-image: url("../assets/images/bg_avis.png") !important;
    }
  }
</style> -->
