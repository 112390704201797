import { createApp } from 'vue'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import App from './App.vue'
import router from './router'
import store from './store'
require('../node_modules/bootstrap/dist/css/bootstrap.css')
require('./assets/css/custom.css')

createApp(App)
  .use(store)
  .use(router)
  .use(VueLoading, {
    loader: 'dots',
    color: '#0033a1'
  }).mount('#app')
