import { createStore } from 'vuex'

// initial state
const state = {
  questions: []
}

// getters are functions.
const getters = {
  questions: state => state.questions
}

// actions
const actions = {
  setUserQuestions ({ commit }, questions) {
    // console.log('setUserQuestions', questions)
    commit('setQuestions', questions)
  }
}

// mutations
const mutations = {
  setQuestions (state, questions) {
    state.questions = questions
  }
}

export default createStore({
  state,
  getters,
  actions,
  mutations
})
